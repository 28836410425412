<template>
  <section id="card-navigation">
    <b-row>
      <!-- pill tabs inside card -->
      <b-col md="12">
        <b-overlay
          rounded="sm"
          spinner-variant="primary"
        >
          <b-card
            class="text-center"
          >
            <b-card-header
              class="justify-content-start p-0 pb-1 mb-1 border-bottom"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="$router.back()"
              >
                <feather-icon
                  class="mr-25"
                  icon="ChevronLeftIcon"
                />
              </b-button>
              <h3 class="mb-0 ml-2 d-flex justify-space-between">
                <span>{{ program.title }}</span>
              </h3>
            </b-card-header>
            <b-tabs
              v-model="activeTab"
              active
              pills
              class="pb-2"
              @input="updateHash"
            >
              <b-tab
                href="#Startups"
                lazy
                title="Startups"
              >
                <b-card
                  class="text-left"
                  title="Startup Progress"
                >
                  <startup-progress />
                </b-card>
              </b-tab>
              <b-tab
                title-item-class="calendarbtn"
                class="text-left"
                href="#Activities"
                lazy
                title="Calendar"
              />
              <b-tab
                class="text-left"
                href="#Modules"
                lazy
                title="Modules"
              >
                <module-manager />
              </b-tab>
              <b-tab
                href="#Workshops"
                lazy
                title="Workshops"
              >
                <b-card
                  class="text-left"
                  title="Manage Workshop"
                >
                  <workshop-manager />
                </b-card>
              </b-tab>
              <b-tab
                href="#MasterClass"
                lazy
                title="Masterclass"
              >
                <b-card
                  class="text-left"
                  title="Manage Masterclass"
                >
                  <master-class-manager />
                </b-card>
              </b-tab>
              <b-tab
                class="text-left"
                href="#E-Meet"
                lazy
                title="Sessions"
              >
                <b-card
                  class="text-left"
                  title="Meeting Manager"
                >
                  <emeet-manager />
                </b-card>
              </b-tab>
              <b-tab
                href="#Exercises"
                lazy
                title="Exercises"
              >
                <b-card
                  class="text-left"
                  title="Exercise Manager"
                >
                  <review-manager />
                </b-card>
              </b-tab>
              <b-tab
                href="#KnowledgeBase"
                lazy
                title="Library"
              >
                <b-card
                  class="text-left"
                  title="Library"
                >
                  <knowledge-base />
                </b-card>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCol, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import StartupProgress from './StartupProgress.vue'
import ReviewManager from './ReviewManager.vue'
import EmeetManager from './EmeetManager.vue'
import WorkshopManager from './WorkshopManager.vue'
import MasterClassManager from './MasterClassManager.vue'
import KnowledgeBase from './KnowledgeBase.vue'
import ModuleManager from './ModuleManager.vue'

export default {
  components: {
    EmeetManager,
    WorkshopManager,
    ModuleManager,
    MasterClassManager,
    ReviewManager,
    StartupProgress,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BTab,
    BTabs,
    BButton,
    KnowledgeBase,
  },
  data() {
    return {
      program: '',
      activeTab: 0,
      tabs: ['#Startups',
        '#Activities',
        '#Modules',
        '#Workshops',
        '#MasterClass',
        '#Sessions',
        '#Exercises',
        '#Library',
        '#Services',
        '#Requests'],
    }
  },

  watch: {
    // Watch for changes in route, activate corresponding tab if hash is changed
    $route: {
      handler(to, from) {
        if (to.hash !== from.hash) {
          this.activeTab = this.tabs.findIndex(tab => tab === to.hash)
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.activeTab = this.tabs.findIndex(tab => tab === this.$route.hash)
    })
  },
  methods: {
    updateHash(e) {
      this.$router.replace(this.$route.path + this.tabs[e])
    },
  },
  apollo: {
    program: {
      query: gql`
        query GetPrograms($programId:Int!) {
        programs_basicinfo_by_pk(id:$programId){
          id
          title
          begin_date
          end_date
        }
      }`,
      variables() {
        return {
          programId: this.$route.params.id,
        }
      },
      update: data => data.programs_basicinfo_by_pk,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.calendarbtn{
  position: absolute;
  right: 20px;
  top: 20px;
  border: solid 1px #7367F0;
  border-radius: 8px;
}
</style>
