<template>
  <!-- Overlay component to show loading state -->
  <b-overlay
    :show="$apollo.loading || mutationLoading"
    rounded="sm"
  >
    <!-- Search bar for exercises -->
    <div class="custom-search d-flex justify-content-start">
      <b-form-group
        class="form-inline"
        label="Search"
        label-size="sm"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search Exercises"
          type="text"
        />
      </b-form-group>
    </div>
    <!-- Table component to display exercises -->
    <exercises-table
      :exercises="exercises"
      :columns="columns"
      :get-link="getLink"
      :operations="operations"
      :search-term="searchTerm"
    />
    <!-- Modal to select a startup -->
    <b-modal
      id="select-startup-modal"
      title="Select Startup"
      @ok="hideModal"
    >
      <b-form-select
        v-model="selectedStartup"
      >
        <option
          disabled
          value=""
        >
          Please select a startup
        </option>
        <option
          v-for="startup in startups"
          :key="startup.sid"
          :value="startup.sid"
        >
          {{ startup.title }}
        </option>
      </b-form-select>
    </b-modal>
  </b-overlay>
</template>

<script>
import gql from 'graphql-tag'
import ExercisesTable from '@/components/exercises/ExercisesTable.vue'
import {
  BFormGroup, BFormInput, BFormSelect, BOverlay, BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BModal,
    ExercisesTable,
  },
  data() {
    return {
      searchTerm: '', // Search term for filtering exercises
      selectedStartup: '', // Selected startup ID
      startups: [], // List of startups
      exercises: [], // List of exercises
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Operation',
          field(row) {
            return row.programs_operationstable.title
          },
          filterOptions: {
            enabled: true,
            customFilter: true,
          },
          width: '45%',
        },
        {
          label: 'Action',
          field: 'action',
          width: '13rem',
          sortable: false,
        },
      ],
      operations: [], // List of operations
      pageLength: 5, // Number of items per page
      moduleId: null, // Current module ID
      type: null, // Current type
    }
  },
  methods: {
    /**
     * Get the link for the specified module and type.
     * @param {Number} moduleId - The ID of the module.
     * @param {String} type - The type of the operation.
     * @returns {String|null} - The URL to navigate to or null if no startup is selected.
     */
    getLink(moduleId, type) {
      this.moduleId = moduleId
      this.type = type
      if (!this.selectedStartup) {
        this.$bvModal.show('select-startup-modal')
        return null
      }
      if (this.type === 'open') {
        return this.$router.push({
          name: 'mentor-review-score',
          params: {
            pid: this.$route.params.id,
            apid: this.moduleId,
            aid: this.selectedStartup,
            rid: this.$route.params.pid,
          },
        })
      }
      return this.$router.push({
        name: 'mentor-review-evaluations',
        params: {
          pid: this.$route.params.id,
          apid: this.moduleId,
          aid: this.selectedStartup,
          rid: this.$route.params.pid,
        },
      })
    },
    /**
     * Hide the modal and navigate to the appropriate URL based on the type.
     */
    hideModal() {
      const routeName = this.type === 'open' ? 'mentor-review-score' : 'mentor-review-evaluations'
      this.$router.push({
        name: routeName,
        params: {
          pid: this.$route.params.id,
          apid: this.moduleId,
          aid: this.selectedStartup,
          rid: this.$route.params.pid,
        },
      })
      this.$bvModal.hide('select-startup-modal')
    },
  },
  apollo: {
    operations: {
      query: gql`
        query getOperations($programId: Int!) {
          programs_operationstable(where: { program_id: { _eq: $programId }, operations_type: { _eq: "Phase" } }) {
            id
            title
            programs_operationstables(where: { operations_type: { _eq: "SubPhase" } }) {
              id
              title
              programs_operationstables {
                id
                title
              }
            }
          }
        }
      `,
      variables() {
        return {
          programId: this.$route.params.id,
        }
      },
      update(data) {
        return data.programs_operationstable || []
      },
    },
    exercises: {
      query: gql`
      query getExercises($programId: Int!, $operationsId: Int!) {
        programs_assignmenttable(order_by: { id: desc }, where: { programs_operationstable: { programs_basicinfo: { id: { _eq: $programId } } }, review_status: { _neq: "draft" }, operations_id: { _eq: $operationsId } }) {
          id
          title
          type
          review_status

          programs_operationstable {
            title
            operations_type
          }
        }
      }
    `,
      variables() {
        return {
          programId: this.$route.params.id,
          operationsId: this.$route.params.oid,
        }
      },
      update(data) {
        return data.programs_assignmenttable || []
      },
    },
    startups: {
      query() {
        return gql`
          query GetStartups($id: Int!, $partnerId: Int!) {
            programs_basicinfo(where: { id: { _eq: $id } }) {
              programs_startupparticipants {
                id
                programs_applicantstable {
                  users_organizationtable {
                    title
                  }
                }
                programs_startupassignmenttables(
                  where: { partner_id: { _eq: $partnerId } }
                  order_by: { id: desc }
                ) {
                  programs_startupparticipant {
                    id
                  }
                }
              }
              programs_partnerstables(where: { id: { _eq: $partnerId } }) {
                is_super
                is_jury
              }
            }
          }
        `
      },
      variables() {
        return {
          id: this.$route.params.id,
          partnerId: this.$route.params.pid,
        }
      },
      update(data) {
        const temp = []
        if (data.programs_basicinfo[0].programs_partnerstables[0].is_super) {
          data.programs_basicinfo[0].programs_startupparticipants.forEach(
            startup => {
              temp.push({
                sid: startup.id,
                title:
                  startup.programs_applicantstable.users_organizationtable.title,
                category:
                      startup.programs_startupassignmenttables.length > 0
                        ? 'Assigned'
                        : 'Program',
              })
            },
          )
        } else {
          data.programs_basicinfo[0].programs_startupparticipants.forEach(
            startup => {
              if (startup.programs_startupassignmenttables.length > 0) {
                temp.push({
                  sid: startup.id,
                  title:
                    startup.programs_applicantstable.users_organizationtable
                      .title,
                  category: 'Assigned',
                })
              }
            },
          )
        }
        return temp
      },
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
